import React from 'react'
import I18n from '../utils/I18n'
import HeroCards from './HeroCards'


const OverviewBanner = ({ currentSlug, lang }) => {
	const i18n = new I18n({ lang })

	let slugs = []
	let titleLabel = i18n.text('overviewBanner_title')
	let descriptionLabel = i18n.text('overviewBanner_description')

	if (currentSlug === 'associateoverview') {
		slugs = ['accreditedprofessionaloverview', 'administratoroverview']
	} else if (currentSlug === 'mulesoftoverview') {
		descriptionLabel = i18n.text('overviewBanner_description_mulesoft')
		slugs = ['associateoverview', 'administratoroverview']
	} else if (currentSlug === 'administratoroverview' || currentSlug === 'architectoverview' || currentSlug === 'developeroverview' || currentSlug === 'salesprofessionaloverview' || currentSlug === 'marketingoverview' || currentSlug === 'consultantoverview' || currentSlug === 'designeroverview') {
		slugs = ['accreditedprofessionaloverview', 'associateoverview']
	} else if (currentSlug === 'accreditedprofessionaloverview') {
		slugs = ['administratoroverview', 'associateoverview'];
		titleLabel = i18n.text('overviewBanner_accredited_title');
		descriptionLabel = i18n.text('overviewBanner_accredited_description');
	}
	else {
		slugs = ['mulesoftoverview', 'associateoverview']
	}

	return (
		<div className="cert-overview-hero">
			{/*
			<tds-alert dismissible="false">
				<span style={{ fontSize: '16px', fontWeight: '400' }}>
					<p dangerouslySetInnerHTML={{ __html: i18n.text('overviewBanner_alert') }}></p>			
				</span>
			</tds-alert>
			*/}

			<div>
				<div className="cert-overview-hero_content_old slds-container_x-large slds-container--center">
					<div className="slds-grid slds-wrap slds-grid_align-spread">
						<div className="slds-size--1-of-1 slds-large-size--1-of-3 cert-overview-hero_col-1">
							<div>
								<div className="cert-overview-hero_title">
									{titleLabel}
								</div>

								<div className="cert-overview-hero_description">
									{descriptionLabel}
								</div>
							</div>
						</div>

						<div className="slds-size--1-of-1 slds-large-size--2-of-3 cert-overview-hero_col-2">
							<HeroCards slugs={slugs} lang={lang} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OverviewBanner
